import React from 'react';
import {generateId} from "../../common/helpers/helpers";
import style from "../../components/SpreadTable/SpreadTable.module.scss";
import {commissionHint, marketLogos, openLinks} from "../../common/helpers/imagesImport";
import {getColorsMarket, Market} from "../../common/helpers/getColorsMarket";
import Tooltip from "../../common/tooltip/Tooltip";
import {openAllLinks} from "../../common/helpers/openLinks";

interface ExchangeBuyProps {
    spread: {
        exchangeSell: any;
        exchangeBuy: {
            exchangeBuyTradeLink: string;
            exchangeBuy: Market;
            exchangeBuyWithdrawLink: string;
            withdrawTips: string;
        }[]
    }
}


const ExchangeBuy: React.FC<ExchangeBuyProps> = ({spread}) => {
    return (
        <td>{spread.exchangeBuy.map((exchangeBuy, index) => (
            <div key={generateId()} className={`${style.exchangeBuy} ${style.lastSpread}`}>
                <div className={style.links}>
                    <Tooltip content='Open all links' direction="top">
                        <img src={openLinks} alt='open all links' onClick={() => openAllLinks(index, spread)}/>
                    </Tooltip>
                </div>
                <div className={style.exchangeLogo}>
                    <a href={exchangeBuy.exchangeBuyTradeLink} target='_blank'>
                        <img src={marketLogos[exchangeBuy.exchangeBuy]} alt={exchangeBuy.exchangeBuy}/>
                    </a>
                </div>
                <div className={style.exchangeName}
                     style={{backgroundColor: getColorsMarket(exchangeBuy.exchangeBuy)}}>
                    <a href={exchangeBuy.exchangeBuyTradeLink} target='_blank'>{exchangeBuy.exchangeBuy}</a>
                </div>
                <div className={style.wd}>
                    <a href={exchangeBuy.exchangeBuyWithdrawLink} target='_blank'>wd</a>
                    {exchangeBuy.withdrawTips &&
                      <div className={style.withDrawContainer}>
                        <Tooltip content={exchangeBuy.withdrawTips} direction="top">
                          <img className={style.withdrawTips} src={commissionHint} alt='Check withdraw commission'/>
                        </Tooltip>
                      </div>
                    }
                </div>
            </div>
        ))}</td>
    );
};

export default ExchangeBuy;