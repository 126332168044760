import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import style from './Portfolio.module.scss';
import {getToken} from "../../common/auth/auth";
import axios from "axios";
import {BASE_URL} from "../../common/api/api";
import PortfolioTable from "./PortfolioTable";
import AllTimeWrapper from "./AllTimeWrapper";

const Portfolio = () => {
    const navigate = useNavigate();
    const [portfolio, setPortfolio]: any = React.useState({});

    const handleBackClick = () => {
        navigate(-1); // Переход назад на одну страницу
    };

    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            };
            const response = await axios.get(`${BASE_URL}/balance/profile`, config);
            setPortfolio(response.data);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // const intervalId = setInterval(() => {
        //     fetchData();
        // }, FETCHING_INTERVAL);
        //
        // return () => {
        //     clearInterval(intervalId);
        // }
    }, []);

    console.log('portfolio', portfolio);

    return (
        <div className={style.wrapper}>
            <div className={style.back}>
                <NavLink className={style.link} to='/balance'>Balance</NavLink>
            </div>
            <div className={style.portfolio}>
                <AllTimeWrapper portfolio={portfolio} />
                <div className={style.assets}>
                    <span className={style.assetsTitle}>Assets</span>
                    <PortfolioTable coins={portfolio?.coins} />
                </div>
            </div>
        </div>
    );
};

export default Portfolio;