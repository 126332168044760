import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../common/api/api";
import { NotificationManager } from "react-notifications";

interface AuthState {
    token: string | null;
    status: 'none' | 'loading' | 'succeed' | 'rejected';
    error: string | null;
}

export const loginAsync = createAsyncThunk('auth/login', async ({ email, password }: { email: string, password: string }) => {
    try {
        const postData = { email, password };
        const response = await axios.post(`${BASE_URL}/auth/signin`, postData);

        if (response.status === 200) {
            NotificationManager.success('Login successful');
            localStorage.setItem('token', response.data.token);
            return response.data.token;
        }
    } catch (error: any) {
        NotificationManager.error(error.response.data.message);
        throw error;
    }
});

const authSlice = createSlice<AuthState, Record<string, (state: AuthState, action: PayloadAction<any>) => void>>({
    name: 'auth',
    initialState: {
        token: localStorage.getItem('token') || null,
        status: 'none',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loginAsync.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(loginAsync.fulfilled, (state, action) => {
            state.status = 'succeed';
            state.token = action.payload;
        })
        builder.addCase(loginAsync.rejected, (state, action) => {
            state.status = 'rejected';
            state.error = action.error.message || 'Authentication failed';
        })
    }
});

export const selectToken = (state: { auth: AuthState }) => state.auth.token;
export const selectAuthError = (state: { auth: AuthState }) => state.auth.error;
export const selectAuthStatus = (state: { auth: AuthState }) => state.auth.status;

export default authSlice.reducer;