import React from 'react';

const Head: React.FC = () => {
  return (
        <thead>
        <tr>
          <th>Currency coin</th>
          <th>Spread</th>
          <th>Buy Market</th>
          <th>Balance</th>
          <th>Chain</th>
          <th>Commission</th>
          <th>Sell Market</th>
          <th>Buy Price</th>
          <th>Income</th>
          <th>Best Buy price</th>
          <th>Best Income</th>
          <th>Created</th>
          <th>Updated</th>
        </tr>
        </thead>
  );
};

export default Head;