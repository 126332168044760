import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from "react-router-dom";
import style from './Balance.module.scss';
import axios from "axios";
import {getToken} from "../../common/auth/auth";
import {BASE_URL} from "../../common/api/api";
import Tooltip from "../../common/tooltip/Tooltip";

enum interval {
    fetching = 10000
}

const FETCHING_INTERVAL: interval = interval.fetching;


type MarketData = {
    usdtInteger: number;
    coinInteger: number;
    lockedInteger: number;
    coinNotes: string;
    lockedNotes: string;
};

// Определяем тип данных для объекта с данными по рынкам
type MarketDataResponse = {
    [key: string]: MarketData;
};

const Balance = () => {
    const navigate = useNavigate();
    const headers = ['Market', 'USDT', 'Coin', 'Locked'];
    const [balance, setBalance] = useState({});
    const fetchData = async () => {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${getToken()}`
                }
            };
            const response = await axios.get(`${BASE_URL}/balance`, config);
            setBalance(response.data);
        } catch (error) {
            console.error('Ошибка при выполнении запроса:', error);
        }
    };
    useEffect(() => {
        fetchData();

        const intervalId = setInterval(() => {
            fetchData();
        }, FETCHING_INTERVAL);

        return () => {
            clearInterval(intervalId);
        }
    }, []);


    const handleBackClick = () => {
        navigate(-1); // Переход назад на одну страницу
    };

    return (
        <div className={style.wrapper}>
            <div className={style.back}>
                <NavLink className={style.link} to='/portfolio'>Portfolio</NavLink>
            </div>
            <table>
                <thead>
                <tr>
                    {headers.map(header => (
                        <th key={header}>{header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {Object.entries(balance).map(([market, values]) => (
                    <tr key={market}>
                        <td>
                            {market}
                        </td>
                        <td>{(values as MarketData).usdtInteger}</td>
                        <td>
                            <Tooltip content={(values as MarketData).coinNotes} direction="top" isTable={true}>
                                {(values as MarketData).coinInteger}
                            </Tooltip>
                        </td>
                        <td>
                            <Tooltip content={(values as MarketData).lockedNotes} direction="top" isTable={true}>
                                {(values as MarketData).lockedInteger}
                            </Tooltip>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default Balance;