import React from 'react';
import Header from "../Header/Header";
import style from './WalletWrapper.module.scss';
import {NavLink} from "react-router-dom";

const WalletWrapper = () => {
    return (
        <>
            <Header/>
            <div className={style.wrapper}>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/portfolio'>Portfolio</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/balance'>Balance</NavLink>
            </div>
        </>
    );
};

export default WalletWrapper;