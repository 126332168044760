import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
import {Provider} from "react-redux";

import App from './App';
import store from "./redux/store";

import 'react-notifications/lib/notifications.css';

const rootElement: HTMLElement | null = document.getElementById('root');

const renderApp = (): React.ReactElement => {
    const app = (
        <BrowserRouter>
            <Provider store={store}>
                <App/>
            </Provider>
            <NotificationContainer/>
        </BrowserRouter>
    );

    rootElement
        ? ReactDOM.createRoot(rootElement).render(app)
        : console.error("Root element with id 'root' not found.");

    return app;
}

renderApp();
