import React, {ChangeEvent, useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useDispatch, useSelector} from "react-redux";
import {
    selectBudgetChange,
    selectBudgetValue,
    selectBudgetByBalanceValue,
    setBudgetChange,
    setBudgetValue,
    setBudgetByBalanceValue
} from "../../redux/budget/budgetSlice";
import {fetchDataAsync} from "../../redux/spreads/spreadsSlice";
import {useDebounce} from "../../common/helpers/useDebounce";
import {selectTheme} from "../../redux/theme/themeSlice";
import style from './FilterPanel.module.scss';
import '../../themes.scss';
import {ThunkDispatch} from "redux-thunk";
import {RootState} from "@reduxjs/toolkit/query";
import {AnyAction} from "@reduxjs/toolkit";
import ExcludeMarket from "../../common/filters/ExcludeMarket/ExcludeMarket";
import ExcludeChain from "../../common/filters/ExcludeChain/ExcludeChain";
import ExcludeCoin from "../../common/filters/ExcludeCoin/ExcludeCoin";


const DEBOUNCE_DELAY = 1000;
const MINIMUM_BUDGET_VALUE = 10;


const FilterPanel: React.FC = () => {
    const dispatch: ThunkDispatch<RootState<any, any, any>, any, AnyAction> = useDispatch();
    const budgetValue = useSelector(selectBudgetValue);
    const budgetByBalanceValue: any = useSelector(selectBudgetByBalanceValue);
    const budgetChange = useSelector(selectBudgetChange);
    const theme = useSelector(selectTheme);
    const debouncedBudgetValue = useDebounce(budgetValue, DEBOUNCE_DELAY);

    const handleClick = () => {
        dispatch(setBudgetByBalanceValue(!budgetByBalanceValue));
        localStorage.setItem('BUDGET_BY_BALANCE', '' + !budgetByBalanceValue);
    };

    const fetchData = async () => {
        dispatch(fetchDataAsync(budgetValue));
    };

    const handleBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setBudgetChange(true));
        const inputValue = e.currentTarget.value;
        dispatch((setBudgetValue(inputValue)));
    }


    useEffect(() => {
        if (debouncedBudgetValue && budgetChange) {
            const validation = !Number.isNaN(budgetValue) && parseInt(budgetValue) >= MINIMUM_BUDGET_VALUE;
            if (validation) {
                fetchData();
                localStorage.setItem('BUDGET_VALUE', budgetValue);
            } else {
                dispatch((setBudgetValue('100')));
                localStorage.setItem('BUDGET_VALUE', '100');
                NotificationManager.error('You can enter a minimum number of 10$');
            }
        }
    }, [debouncedBudgetValue]);

    return (
        <div className={`${style.container} ${theme}`}>
            <div className={style.filterWrapper}>
                <div className={`${style.selectChains} ${style.filterItem}`}>
                    <ExcludeMarket/>
                </div>
                <div className={`${style.selectExchanges} ${style.filterItem}`}>
                    <ExcludeChain/>
                </div>
                <div className={`${style.coinWrapper} ${style.filterItem}`}>
                    <ExcludeCoin/>
                </div>
            </div>
            <div className={style.budgetWrapper}>
                <div className={style.switch} onClick={handleClick}>
                    <svg viewBox="0 0 21.02 21.27" xmlns="http://www.w3.org/2000/svg"
                         className={`${style.svg} ${budgetByBalanceValue ? style.rotate : ''}`}>
                        <g data-name="Layer 2" id="Layer_2">
                            <g data-name="Layer 1" id="Layer_1-2">
                                <polygon fill="#8e8e8e" points="0.51 20.45 0.51 14.45 6.51 14.45 0.51 20.45"/>
                                <path fill="none" stroke="#8e8e8e" strokeLinecap="square" strokeMiterlimit="10"
                                      d="M20.52,10.77a10,10,0,0,1-18.7,4.94"/>
                                <polygon fill="#8e8e8e" points="20.51 0.82 20.51 6.82 14.51 6.82 20.51 0.82"/>
                                <path fill="none" stroke="#8e8e8e" strokeLinecap="square" strokeMiterlimit="10"
                                      d="M.5,10.5A10,10,0,0,1,19.2,5.56"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className={style.budget}>
                    <span className={style.name}>{!budgetByBalanceValue ? 'Budget' : 'Budget by balance'}</span>
                    {!budgetByBalanceValue &&
                      <>
                        <div className={style.budgetInputWrapper}>
                          <span>$&nbsp;	</span>
                          <input className={style.budgetInput} type='number' onChange={handleBudgetChange}
                                 value={budgetValue}/>
                        </div>
                        <span className={style.name}>Min budget 10$</span>
                      </>
                    }
                </div>
            </div>
        </div>
    );
};

export default FilterPanel;