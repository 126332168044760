import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL, DEV_URL} from "../../common/api/api";
import {filterSpreads, getSpreadsLength} from "../../common/helpers/helpers";
import {getAuth} from "../../common/helpers/auth";

interface SpreadsState {
    spreads: Record<string, any>; // Adjust the type based on your data structure
    status: 'none' | 'loading' | 'succeed';
    error: any; // Adjust the type based on your error handling
}

export const fetchDataAsync = createAsyncThunk('api/fetchData', async (budgetValue: string) => {
    try {
        const budgetByBalanceStorage = localStorage.getItem('BUDGET_BY_BALANCE');
        const parsedBudgetByBalance = budgetByBalanceStorage ? JSON.parse(budgetByBalanceStorage) : false;
        const response = await axios.get(`${BASE_URL}/spread/map/new?budget=${budgetValue}${parsedBudgetByBalance ? '&byBalance=true' : ''}`, getAuth());
        return getSpreadsLength(response.data) ? filterSpreads(response.data) : {};
    } catch (error) {
        throw error;
    }
});

const apiSlice = createSlice<SpreadsState, Record<string, (state: SpreadsState, action: PayloadAction<any>) => void>>({
    name: 'spreads',
    initialState: {
        spreads: {},
        status: 'none',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDataAsync.pending, (state) => {
            state.status = 'loading';
            state.error = false;
        })
        builder.addCase(fetchDataAsync.fulfilled, (state, action) => {
            state.status = 'succeed';
            state.spreads = action.payload;
            state.error = false;
        })
        builder.addCase(fetchDataAsync.rejected, (state, action) => {
            state.status = 'loading';
            console.log('action.', action);
            state.error = action.error.message;
        })
    }
});

export const selectSpreads = (state: { spreads: SpreadsState }) => state.spreads.spreads;
export const selectSpreadsError = (state: { spreads: SpreadsState }) => state.spreads.error;

export default apiSlice.reducer;