import {useNavigate} from "react-router-dom";
import {Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import style from './Login.module.scss';
import {useDispatch, useSelector} from "react-redux";
import {loginAsync, selectAuthError, selectAuthStatus} from "../../redux/auth/authSlice";


const Login = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  const authError = useSelector(selectAuthError);
  const navigate = useNavigate();

  const onFinish = async ({email, password}) => {
    try {
      await dispatch(loginAsync({email, password}));
      navigate("/spreads");
    } catch {
      console.log('Login failed');
    }
  };

  if (authStatus === 'loading') return <div>Loading...</div>
  return (
    <div className={style.wrapper}>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="email"
          rules={[{
            required: true, message: 'Please input your Email!',
          },]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{
            required: true, message: 'Please input your Password!',
          },]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon"/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
          <div>
            {/*Or <NavLink to='/signup'>register now</NavLink>*/}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;