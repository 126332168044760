import React from 'react';
import Header from "../Header/Header";
import Balance from "./Balance";

const BalanceWrapper = () => {
    return (
        <>
            <Header/>
            <Balance/>
        </>
    );
};

export default BalanceWrapper;