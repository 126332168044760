import React from 'react';
import style from './Portfolio.module.scss';
import {determineStateBalance, formatedNumber, generateId} from "../../common/helpers/helpers";
import {getBalanceClass} from "../../common/helpers/getBalanceClass";

const PortfolioTable = ({coins}: any) => {
    return (
        <table>
            <thead>
            <tr>
                <th>Name</th>
                <th>1h%</th>
                <th>24h%</th>
                <th>7d%</th>
                <th>90d%</th>
                <th>Holdings</th>
                <th>Profit/Loss</th>
            </tr>
            </thead>
            <tbody>
            {coins && coins.map((coin: any) => (
                <tr key={generateId()}>
                    <td>
                        <div className={style.name}>
                            <img src={'https://s2.coinmarketcap.com/static/img/coins/64x64/10407.png'} alt={coin.coin}/>
                            <span>{coin.coin}</span>
                        </div>
                    </td>
                    <td style={{paddingLeft: '15px'}}>{coin.hour1DifferenceInPercent ? coin.hour1DifferenceInPercent : '-'}</td>
                    <td style={{paddingLeft: '15px'}}>{coin.hour24DifferenceInPercent ? coin.hour24DifferenceInPercent : '-'}</td>
                    <td style={{paddingLeft: '15px'}}>{coin.day90DifferenceInPercent ? coin.day90DifferenceInPercent : '-'}</td>
                    <td style={{paddingLeft: '15px'}}>{coin.hour1DifferenceInPercent ? coin.hour1DifferenceInPercent : '-'}</td>
                    <td>
                        <div className={style.holdings}>
                            <span className={style.holdingsBalance}>${formatedNumber(coin.balanceInUsdt)}</span>
                            <span className={style.holdingsPrice}>{formatedNumber(coin.balance)} {coin.coin}</span>
                        </div>
                    </td>
                    <td>
                        <div className={style.allProfit}>
                            <span
                                className={style.allProfitBalance}>
                                {determineStateBalance(coin.allTimeDifferenceInUsdt)}
                                &nbsp;
                                ${formatedNumber(coin.allTimeDifferenceInUsdt)}
                            </span>
                            <span
                                className={getBalanceClass(coin.allTimeDifferenceInUsdt)}>{coin.allTimeDifferenceInPercent}%</span>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default PortfolioTable;