export function processItem(item: any) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const newWindow = window.open(item, '_blank');
            resolve(newWindow);
        }, 0)
    });
};
export async function processItems(items: any) {
    const promises = items.map(processItem);
    const windows = await Promise.all(promises);
};

export function isFirefox() {
    return /Firefox/i.test(navigator.userAgent);
};

export const openAllLinks = (id: any, spread: any) => {
    const links: any = [];
    const linksArray: any = [];
    spread.exchangeBuy.forEach((exchangeBuy: any, index: any) => {
        if (id === index) {
            links.push({
                buyTradeLink: exchangeBuy.exchangeBuyTradeLink,
            });
        }
    });
    spread.exchangeSell.forEach((exchangeSell: any, index: any) => {
        if (id === index) {
            links.push({
                sellTradeLink: exchangeSell.exchangeSellTradeLink,
            });
        }
    });
    spread.exchangeBuy.forEach((exchangeBuy: any, index: any) => {
        if (id === index) {
            links.push({
                buyWithdrawLink: exchangeBuy.exchangeBuyWithdrawLink
            });
        }
    });
    spread.exchangeSell.forEach((exchangeSell: any, index: any) => {
        if (id === index) {
            links.push({
                sellDepositLink: exchangeSell.exchangeSellDepositLink
            });
        }
    });

    links.forEach((item: any) => {
        linksArray.push(item.buyTradeLink);
        linksArray.push(item.buyWithdrawLink);
        linksArray.push(item.sellTradeLink);
        linksArray.push(item.sellDepositLink);
    });

    const filteredLinksArray = linksArray.filter((link: any) => link !== undefined && link !== null);
    if(isFirefox()) {
        const reverse = filteredLinksArray.reverse();
        processItems(reverse);
    } else {
        processItems(filteredLinksArray);
    }
}