import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSpreads} from "../../redux/spreads/spreadsSlice";
import {getSpreadsLength} from "../../common/helpers/helpers";
import style from './Header.module.scss';
import {selectTheme, setTheme} from "../../redux/theme/themeSlice";
import lightIcon from '../../assets/images/light.png';
import darkIcon from '../../assets/images/dark.png';
import {NavLink} from "react-router-dom";

const Header: React.FC = (): React.ReactElement => {
    const spreads = useSelector(selectSpreads);
    const theme: string = useSelector(selectTheme);
    const dispatch = useDispatch();
    const spreadsCount: number = getSpreadsLength(spreads);
    const setThemeIcon = theme === 'light' ? lightIcon : darkIcon;
    const changeTheme = () => {
        const themeValue = theme === 'light' ? 'dark' : 'light';
        dispatch(setTheme(themeValue));
        localStorage.setItem('theme', themeValue);
    }
    return (
        <header className={`${style.header} ${theme}`}>
            <div className={style.titleWrapper}>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/spreads'>Spreads</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/portfolio'>Wallet</NavLink>
                <NavLink className={({ isActive }) =>
                    isActive ? style.activeLink : style.link
                } to='/api-status'>API Status</NavLink>
                <img onClick={changeTheme} className={style.themeIcon} src={setThemeIcon}/>
            </div>
            <div className={style.infoWrapper}>
                <span>Users online: <strong>1</strong></span>
                <span>Spreads count: <strong>{spreadsCount}</strong></span>
            </div>
        </header>
    );
};

export default Header;